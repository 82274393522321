import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/sass/overrides.sass';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

const theme = {
  primary: '#002C77',
  secondary: '#8D6E63',
  accent: '#ff5722'
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
});
